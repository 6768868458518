import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import ExcelJS from 'exceljs';
import instance from '../AxiosInstance';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Box,
    TextField,
    Button,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Paper,
} from '@material-ui/core';
import Navbar from './Navbar';
import PieChartShimmer from './PieChartShimmer';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '55px',
    },
    header: {
        textAlign: 'center',
    },
    formContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#eee',
        width: '100%',
    },
    formInput: {
        margin: '10px 0px',
        padding: '15px',
        backgroundColor: '#f0f0f0',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: '300px',
        height: '15px',
    },
    button: {
        margin: '10px',
        backgroundColor: '#f73942',
        color: 'white',
        fontSize: '12px'
    },
    tableTitle: {
        textAlign: 'center',
    },
    dataTable: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    filters: {
        padding: '0px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    input: {
        marginRight: theme.spacing(1),
        width: '300px'
    },
    dataList: {
        backgroundColor: '#f0f0f0',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: '300px',
        maxHeight: '200px',
        overflowY: 'auto',
    },
    TableHeader: {
        color: '#333',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        padding: '8px',
        fontSize: '10px'
    },
    TableRow: {
        whiteSpace: 'nowrap',
        padding: '8px',
        fontSize: '10px'
    },
    inputField: {
        margin: '10px',
        padding: '4px 6px',
        backgroundColor: '#f9f9f9',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: '130px',
        fontSize: "1px"
    },
}));
const Durationreport = () => {
    const classes = useStyles();
    const currentDate = new Date().toISOString().split('T')[0];
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [chartInitialized, setChartInitialized] = useState(false);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [yes, setYes] = useState(false);
    const [data2, setData2] = useState([]);
    const [filteredData2, setfilteredData2] = useState([]);
    const [startDate, setStartDate] = useState('2024-02-10');
    const [endDate, setEndDate] = useState(currentDate);
    const [searchTerm, setSearchTerm] = useState('');
    const [isDateSelected, setIsDateSelected] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await instance('/api/iwary/getAllFinalReportData');

            setData2(response.data);
            setfilteredData2(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData2();
    }, [yes]);

    const fetchData2 = async () => {
        try {
            const response = await instance('/api/iwary/getAllZoneduration');
            const jsonData = await response.data;
            const uniqueData = jsonData.filter((item, index, self) =>
                index === self.findIndex((t) => t.vehno === item.vehno)
            );
            setData(uniqueData);
            setFilteredData(uniqueData);
            setSelectedVehicle('');
            setStartDate('');
            setEndDate('');
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (selectedVehicle && chartInitialized) {
            updatePieChart();
        }
    }, [selectedVehicle, chartInitialized]);

    const handleReset = () => {
        handleyes();
        setSelectedVehicle('');
        setfilteredData2(data2)
    };

    const handleSelect = (event) => {
        const selectedValue = event.target.value;
        setSelectedVehicle(selectedValue);
        setFilteredData(selectedValue ? data.filter(item => item.vehno === selectedValue) : []);
        setChartInitialized(true);
        setSearchTerm(selectedValue)
    };

    const handleDateChange = (event) => {
        setIsDateSelected(true)
        const startOfDay = '00:00:00';
        const endOfDay = '23:59:59';
        const formattedStartDate = startDate ? `${startDate} ${startOfDay}` : null;
        const formattedEndDate = endDate ? `${endDate} ${endOfDay}` : null;

        setFilteredData(data.filter(item => {
            const itemDate = new Date(item.site_intime);

            const startDateFilter = !formattedStartDate || itemDate >= new Date(formattedStartDate);
            const endDateFilter = !formattedEndDate || itemDate <= new Date(formattedEndDate);

            return startDateFilter && endDateFilter;
        }));
    };



    const handleyes = () => {
        setIsDateSelected(false)
        setYes(!yes)
        d3.select('#pie-chart').selectAll('*').remove();
        d3.select('#legend').selectAll('*').remove();
        d3.select('#value-list').selectAll('*').remove();

    }

    const updatePieChart = () => {

        d3.select('#pie-chart').selectAll('*').remove();
        d3.select('#legend').selectAll('*').remove();
        d3.select('#value-list').selectAll('*').remove();

        const width = 600;
        const height = 250;
        const radius = Math.min(width, height) / 2;

        const color = d3.scaleOrdinal()
            .domain(["Parking", "Shopfloor", "Bay", "Washing Zone Waiting", "Washing Zone"])
            .range(d3.schemeSet2);

        const svg = d3.select('#pie-chart')
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${width / 2},${height / 2})`);

        const pie = d3.pie()
            .sort(null)
            .value((d) => d.value);

        const arc = d3.arc()
            .innerRadius(radius * 0.4)
            .outerRadius(radius * 0.8);

        const filteredData = data.filter((item) => item && item.vehno === selectedVehicle)[0];

        if (!filteredData) return;


        const pieData = Object.keys(filteredData)
            .filter((key) => {
                return key !== "id" && key !== "vehno" && key !== "min_bay_intime" && key !== "min_shopfloor_intime" && key !== "site_intime" && key !== "site_outime" && key !== "min_washing_intime" && key !== "sid" && key !== "site" && key !== "max_bay_outime" && key !== "max_shopfloor_outime" && key !== "max_washing_outime" && filteredData[key] && Object.keys(filteredData[key]).length > 0; // Include the condition inside the filter function
            })
            .map((key) => {
                const data = filteredData[key];
                let value = 0;

                if (data && typeof data.days === "number") {
                    value += data.days * 24 * 3600;
                }

                if (data && typeof data.hours === "number") {
                    value += data.hours * 3600;
                }
                if (data && typeof data.minutes === "number") {
                    value += data.minutes * 60;
                }
                if (data && typeof data.seconds === "number") {
                    value += data.seconds;
                }

                const label =
                    key === "washingzone_waiting" ? "Washing Zone Waiting" :
                        key === "parking" ? "Parking" :
                            key === "shopfloor" ? "Shopfloor" :
                                key === "bay" ? "Bay" :
                                    key === "washingzone" ? "Washing Zone" :
                                        key;

                if (!data) {
                    return { label, value: "0d:00:00:00" };
                }

                return { label, value };
            });


        const arcs = svg.selectAll('arc')
            .data(pie(pieData))
            .enter()
            .append('g')
            .attr('class', 'arc');

        arcs.append('path')
            .attr('d', arc)
            .attr('fill', (d) => color(d.data.label));

        arcs.each(function (d) {
            const arcNode = d3.select(this);
            const isSmallArc = d.endAngle - d.startAngle < 0.2;

            if (!isSmallArc) {
                arcNode.append('text')
                    .attr('transform', () => {
                        const centroid = arc.centroid(d);
                        const x = centroid[0];
                        const y = centroid[1];
                        const angle = Math.atan2(y, x);

                        const xOffset = Math.cos(angle) * (radius * 1.2);
                        const yOffset = Math.sin(angle) * (radius * 0.9);

                        return `translate(${xOffset}, ${yOffset})`;
                    })
                    .attr('text-anchor', () => {
                        const centroid = arc.centroid(d);
                        return centroid[0] > 0 ? 'start' : 'end';
                    })
                    .attr('fill', 'black')
                    .style('font-size', '13px')
                    .text(() => d.data.label);
            }
        });



        const legend = d3.select("#legend")
            .append("ul")
            .attr("class", "legend");

        const filteredColorDomain = color.domain().filter(label => pieData.some(data => data.label === label));

        const legendItems = legend.selectAll("li")
            .data(filteredColorDomain)
            .enter()
            .append("li")
            .style("color", (d) => color(d))
            .style("width", "200px")
            .text((d) => d);

        // Value List
        const valueList = d3.select('#value-list')
            .append('ul')
            .attr('class', 'value-list');

        valueList.selectAll('li')
            .data(pieData)
            .enter()
            .append('li')
            .style('list-style-type', 'none')
            .text((d) => ` ${formatTime(d.value)}`);

    };

    const formatTime = (seconds) => {
        if (isNaN(seconds) || seconds === undefined) return 'Less than 5 seconds';

        const days = Math.floor(seconds / (3600 * 24));
        seconds -= days * 3600 * 24;
        const hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        const minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;

        let timeString = '';
        if (days > 0) timeString += `${days} days, `;
        if (hours > 0 || timeString !== '') timeString += `${hours} hours, `;
        if (minutes > 0 || timeString !== '') timeString += `${minutes} minutes, `;
        if (seconds > 0 || timeString === '') timeString += `${seconds} seconds`;

        if (timeString.trim() === '') return '0 seconds';
        return timeString.trim();
    };

    useEffect(() => {
        filterData();
    }, [searchTerm]);

    const filterData = () => {
        let filtered = data2.filter(item => {
            const vehicleNumber = item.vehiclenumber ? item.vehiclenumber.toLowerCase() : '';
            const searchLower = searchTerm ? searchTerm.toLowerCase() : '';

            const searchFilter = vehicleNumber.includes(searchLower);

            return searchFilter;
        });
        setfilteredData2(filtered);
    };


    useEffect(() => {
        renderTable();
    }, [filteredData2]);

    const renderTable = () => {
        const table = d3.select('#dataTable');
        table.selectAll('*').remove();

        const headers = filteredData2.length > 0 ? Object.keys(filteredData2[0]) : [];

        if (headers.length > 0) {
            const thead = table.append('thead');
            const tbody = table.append('tbody');

            thead.append('tr')
                .selectAll('th')
                .data(headers)
                .enter()
                .append('th')
                .text(d => d)
                .style('color', '#333') // Text color for headers
                .style('font-weight', 'bold') // Font weight for headers
                .style('padding', '10px') // Padding for headers
                .style('border', '1px solid #ccc') // Border for headers
                .style('border-radius', '1px') // Border radius for headers
                .style('background-color', '#e0e0e0') // Background color for headers
                .style('font-size', '13px'); // Font size for headers

            const rows = tbody.selectAll('tr')
                .data(filteredData2)
                .enter()
                .append('tr')
                .selectAll('td')
                .data(d => Object.values(d))
                .enter()
                .append('td')
                .text((value, index, nodes) => {
                    // Check if the value corresponds to a time-related column
                    if (
                        headers[index] === 'intime' ||
                        headers[index] === 'outime'
                    ) {
                        // Check if value is null or empty
                        if (!value || value === '') {
                            return ''; // Return empty string if value is null or empty
                        } else {
                            // Convert the time using Moment.js
                            return value
                        }
                    } else if (headers[index] === 'duration' && typeof value === 'object') {
                        // Render duration as time if it's the duration field and value is an object
                        return formatDuration(value);
                    } else {
                        return value; // Return the original value for other columns
                    }
                })
                .style('padding', '8px') // Padding for table cells
                .style('border', '1px solid #ccc') // Border for table cells
                .style('font-size', '12px'); // Font size for cells

            // // Add background color to rows based on a condition
            // rows.style('background-color', d => {
            //     return d.firstescalation === 'Violated' ? 'rgba(255, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0)';
            // });

            // Add hover effect to rows
            rows.style('cursor', 'pointer')
                .on('mouseover', function () {
                    d3.select(this).style('background-color', '#f0f0f0');
                })
                .on('mouseout', function () {
                    d3.select(this).style('background-color', d => {
                        return 'rgba(0, 0, 0, 0)';
                    });
                });
        }
    };


    const formatDuration = duration => {
        if (duration === null) {
            return '0d 00:00:00';
        }

        const days = duration.days !== undefined ? duration.days : 0;
        const hours = duration.hours !== undefined ? duration.hours : 0;
        const minutes = duration.minutes !== undefined ? duration.minutes : 0;
        const seconds = duration.seconds !== undefined ? duration.seconds : 0;

        let formattedDuration = '';

        if (days > 0) {
            formattedDuration += `${days}d `;
        }

        formattedDuration += `${hours.toString().padStart(2, '0')}:`;
        formattedDuration += `${minutes.toString().padStart(2, '0')}:`;
        formattedDuration += `${seconds.toString().padStart(2, '0')}`;

        return formattedDuration;
    };

    const handleDownload = async () => {
        try {
            if (filteredData2.length > 0) {
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Security Data');

                const headers = Object.keys(filteredData2[0]);
                worksheet.addRow(headers);

                filteredData2.forEach((rowData) => {
                    const values = Object.values(rowData);
                    worksheet.addRow(values);
                });

                const buffer = await workbook.xlsx.writeBuffer();

                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });

                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'FinalDuration_Report.xlsx';
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } else {
                alert('No data available to generate Excel.');
            }
        } catch (error) {
            console.error('Error generating Excel:', error);
            alert('Error generating Excel. Please try again.');
        }
    };

    return (
        <>
            <Navbar />
            <div className={classes.container}>

                <div className={classes.formContainer}>
                    <Box id="container" style={{ display: 'flex', alignItems: 'center', marginLeft: '30px' }}>
                        <TextField
                            list="vehicleList"
                            name="selectedVehicle"
                            value={selectedVehicle}
                            onChange={handleSelect}
                            placeholder="Select or type Vehicle"
                            className={classes.input}
                            InputProps={{
                                autoComplete: 'off',
                                inputProps: {
                                    list: 'vehicleList',
                                    autoComplete: 'off',
                                },
                            }}
                        />
                        <datalist id="vehicleList" className={classes.dataList}>
                            <option value="">Select a vehicle</option>
                            {isDateSelected ? (
                                filteredData.map((item) => (
                                    <option key={item.id} value={item.vehno}>
                                        {item.vehno}
                                    </option>
                                ))
                            ) : (
                                data.map((item) => (
                                    <option key={item.id} value={item.vehno}>
                                        {item.vehno}
                                    </option>
                                ))
                            )}

                        </datalist>
                        <Button onClick={handleReset} className={classes.button}>
                            Reset
                        </Button>

                    </Box>
                    <h3 variant="h2" className={classes.header}>
                        Vehicle Duration Report
                    </h3>
                    <Box id="container" style={{ display: 'flex', alignItems: 'center', marginRight: '30px' }}>
                        <TextField
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className={classes.inputField}
                            InputProps={{
                                style: { fontSize: '10px' },
                            }}
                        />
                        <TextField
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className={classes.inputField}
                            InputProps={{
                                style: { fontSize: '10px' },
                            }}
                        />
                        <Button onClick={handleDateChange} variant="contained" color="secondary" style={{ backgroundColor: '#4287f5' }} className={classes.button}>
                            Search
                        </Button>
                    </Box>
                </div>
                <div style={{
                    margin: '0px 30px 30px 30px',
                }}>
                    <Grid container xs={12} className={classes.dataTable}>
                        <Grid container xs={6}>

                            <Box style={{ maxWidth: '100%', maxHeight: '450px', overflowY: 'auto' }} >
                                <h3 style={{ textAlign: 'center' }}>Vehicle Duration Table</h3>
                                <Table  >
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: '#e0e0e0' }}>
                                            <TableCell className={classes.TableHeader}>Vehicle Number</TableCell>
                                            <TableCell className={classes.TableHeader}>Parking</TableCell>
                                            <TableCell className={classes.TableHeader}>Shopfloor</TableCell>
                                            <TableCell className={classes.TableHeader}>Bay</TableCell>
                                            <TableCell className={classes.TableHeader}>Washing Zone Waiting</TableCell>
                                            <TableCell className={classes.TableHeader}>Washing Zone</TableCell>
                                            <TableCell className={classes.TableHeader}>Site</TableCell>
                                        </TableRow>

                                    </TableHead>
                                    <TableBody>
                                        {filteredData.map((item, index) => (
                                            <TableRow key={item.id} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5' }}>
                                                <TableCell className={classes.TableRow}>{item.vehno}</TableCell>
                                                <TableCell className={classes.TableRow}>{formatDuration(item.parking)}</TableCell>
                                                <TableCell className={classes.TableRow}>{formatDuration(item.shopfloor)}</TableCell>
                                                <TableCell className={classes.TableRow}>{formatDuration(item.bay)}</TableCell>
                                                <TableCell className={classes.TableRow}>{formatDuration(item.washingzone_waiting)}</TableCell>
                                                <TableCell className={classes.TableRow}>{formatDuration(item.washingzone)}</TableCell>
                                                <TableCell className={classes.TableRow}>{formatDuration(item.site)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>



                        </Grid>
                        <Grid container xs={6}>
                            <div style={{ width: '100%',display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column', 
                                        alignItems: 'center' }}>
                                <h3 style={{ textAlign: 'center', marginBottom: '35px' }}> Vehicle Duration Piechart</h3>

                                {selectedVehicle && chartInitialized ? (
                                    <div style={{
                                        margin: '20px', display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <div id="pie-chart"></div>
                                        <div style={{ display: 'flex', justifyContent: 'space-around', margin: '15px' }}>
                                            <div style={{ width: '20%', fontSize: '12px' }} id="legend"></div>
                                            <div style={{ width: '60%', fontSize: '12px' }} id="value-list"></div>
                                        </div>
                                    </div>
                                ) : (
                                    <PieChartShimmer />
                                )}

                            </div>
                        </Grid>


                    </Grid>
                    <div className={classes.filters} style={{ marginTop: '20px' }}>
                        <h3 style={{ textAlign: 'center' }}>Final Vehicle Report</h3>
                        <Button style={{ color: 'white', backgroundColor: '#1fa31f', fontSize: '11px' }} onClick={handleDownload}>Download</Button>
                    </div>
                    <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                        <Table id="dataTable" style={{ width: '100%' }}></Table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Durationreport;
