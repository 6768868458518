import React, { useState, useEffect } from 'react';
import { Container, Card, CardContent, Typography, Button, TextField, Grid } from '@mui/material';
import Navbar from './Navbar';
import { style } from 'd3';
import axios from 'axios';
import { Box } from '@material-ui/core';
import instance from '../AxiosInstance';

const AdminPortal = () => {
    const [users, setUsers] = useState([]);
    const [verifiedUsers, setVerifiedUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchVerifiedTerm, setSearchVerifiedTerm] = useState('');
    const [userChanged, setUserChanged] = useState(false)



    console.log('users: ', users);
    console.log('verifiedUsers: ', verifiedUsers);

    useEffect(() => {
        const fetchUnverifiedUsers = async () => {
            try {
                const response = await instance.get('/api/auth/unverifiedUsers');
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching unverified users:', error);
            }
        };

        const fetchVerifiedUsers = async () => {
            try {
                const response = await instance.get('/api/auth/verifiedUsers');
                setVerifiedUsers(response.data);
            } catch (error) {
                console.error('Error fetching verified users:', error);
            }
        };

        fetchUnverifiedUsers();
        fetchVerifiedUsers();
    }, [userChanged]);




    const handleApprove = async (userId) => {

        const confirmed = window.confirm('Are you sure you want to verify this user?');

        if (!confirmed) {
            return;
        }

        try {
            const response = await instance.put(`/api/auth/verifyUser/${userId}`);
            setUserChanged(!userChanged)
            return response.data;
        } catch (error) {
            console.error('Error:', error.response.data);
            throw new Error('Failed to verify user.');
        }
    };


    const handleDeactive = async (userId) => {
        const confirmed = window.confirm('Are you sure you want to Deactivate this user?');

        if (!confirmed) {
            return;
        }

        try {
            const response = await instance.put(`/api/auth/unVerifyUser/${userId}`);
            setUserChanged(!userChanged)
            return response.data;
        } catch (error) {
            console.error('Error:', error.response.data);
            throw new Error('Failed to deactivate user.');
        }
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredUsers = users.filter((user) =>
        user.user_phone.includes(searchTerm)
    );

    const handleVerifiedSearch = (event) => {
        setSearchVerifiedTerm(event.target.value);
    };
    const filteredVerifiedUsers = verifiedUsers.filter((user) =>
        user.user_phone.includes(searchVerifiedTerm)
    );

    const getRoleColor = (role) => {
        switch (role) {
            case 'security':
                return 'Blue';
            case 'advisor':
                return '#eb7434';
            default:
                return '#000000';
        }
    };

    const getDisplayRole = (userRole) => {
        switch (userRole.toLowerCase()) {
            case 'security':
                return 'Security';
            case 'advisor':
                return 'Advisor';

            default:
                return userRole;
        }
    };


    return (
        <>
            <Navbar />
            <Box style={{ backgroundColor: '#ddd', marginTop: '55px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h3 style={{ textAlign: 'center', }}>Admin Portal</h3>
            </Box>
            <Grid container xs={12} maxWidth="xl" style={{ marginTop: '10px', justifyContent: 'center' }}>

                <Grid item spacing={2} xs={6} >
                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <h3 style={{ textAlign: 'center' }}>Unverified Users</h3>
                        <TextField
                            fullWidth
                            label="Search by Phone Number"
                            variant="outlined"
                            value={searchTerm}
                            onChange={handleSearch}
                            style={{ marginBottom: '20px', width: '240px', height: '30px' }}
                            inputProps={{
                                style: { fontSize: '13px', padding: '12px' }
                            }}
                            InputLabelProps={{
                                style: {
                                    fontSize: '10px'
                                }
                            }}

                        />

                    </div>
                    <div style={{ maxHeight: '440px', overflowY: 'auto' }}>
                        {filteredUsers.map((user) => (
                            <Grid item key={user.user_id} xs={12} >
                                <Card style={{
                                    width: '85%',
                                    margin: '10px auto',
                                    backgroundColor: '#f5f5f5',
                                    padding: '10px',
                                    marginBottom: '10px',
                                    display: 'flex',
                                }}>
                                    <CardContent style={{ flex: '1' }}>
                                        <Typography style={{ fontSize: '17px', padding: '4px 0px', borderRadius: '5px', marginBottom: '5px', fontWeight: 'bold' }} variant="h6">{user.user_username}</Typography>
                                        <Typography style={{ fontSize: '13px' }} variant="body1">Email: {user.user_email}</Typography>
                                        <Typography style={{ fontSize: '13px' }} variant="body1">Phone: {user.user_phone}</Typography>
                                    </CardContent>
                                    <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', padding: '10px' }}>
                                        <Button variant="bordered" style={{ color: getRoleColor(user.user_role), fontSize: '14px', fontWeight: 'bold' }}>
                                            {getDisplayRole(user.user_role)}
                                        </Button>
                                        <div>
                                            {/* <Button
                                                variant="bordered"
                                                color="primary"
                                                style={{ backgroundColor: '#f5f5f5', color: getRoleColor(user.user_role),fontSize: '11px',marginRight:'10px',fontWeight:'bold' }}
                                            >
                                                {getDisplayRole(user.user_role)}
                                            </Button> */}
                                            {/* <Typography variant="body1" style={{ color: getRoleColor(user.user_role), fontSize: '14px', fontWeight: 'bold' }}>
                                                {getDisplayRole(user.user_role)}
                                            </Typography> */}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleApprove(user.user_id)}
                                                style={{ backgroundColor: 'green', fontSize: '10px', marginRight: '10px' }}
                                            >
                                                Approve
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"

                                                style={{ backgroundColor: 'red', fontSize: '10px' }}
                                            >
                                                Reject
                                            </Button>

                                        </div>
                                    </div>
                                </Card>
                            </Grid>

                        ))}
                    </div>
                </Grid>
                <Grid item spacing={2} xs={6} >
                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <h3 style={{ textAlign: 'center' }}>Verified Users</h3>
                        <TextField
                            fullWidth
                            label="Search by Phone Number"
                            variant="outlined"
                            value={searchVerifiedTerm}
                            onChange={handleVerifiedSearch}
                            style={{ marginBottom: '20px', width: '240px', height: '30px' }}
                            inputProps={{
                                style: { fontSize: '13px', padding: '12px' }
                            }}
                            InputLabelProps={{
                                style: {
                                    fontSize: '10px'
                                }
                            }}

                        />

                    </div>
                    <div style={{ maxHeight: '440px', overflowY: 'auto' }}>
                        {filteredVerifiedUsers.map((user) => (
                            <Grid item key={user.user_id} xs={12}>
                                <Card style={{
                                    width: '85%',
                                    margin: '10px auto',
                                    backgroundColor: '#f5f5f5',
                                    padding: '10px',
                                    marginBottom: '10px',
                                    display: 'flex',
                                }}>
                                    <CardContent style={{ flex: '1' }}>
                                        <Typography style={{ fontSize: '17px', padding: '4px 0px', borderRadius: '5px', marginBottom: '5px', fontWeight: 'bold' }} variant="h6">{user.user_name}</Typography>
                                        <Typography style={{ fontSize: '13px' }} variant="body1">Email: {user.user_email}</Typography>
                                        <Typography style={{ fontSize: '13px' }} variant="body1">Phone: {user.user_phone}</Typography>
                                    </CardContent>
                                    <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', padding: '10px' }}>
                                        <Typography variant="body1" style={{ color: getRoleColor(user.user_role), fontSize: '14px', fontWeight: 'bold' }}>
                                            {getDisplayRole(user.user_role)}
                                        </Typography>
                                        <div >
                                            <Button
                                                variant="bordered"
                                                color="primary"
                                                style={{ backgroundColor: '#f5f5f5', color: '#4287f5', fontSize: '11px', marginRight: '10px', fontWeight: 'bold' }}
                                            >
                                                Verified User
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleDeactive(user.user_id)}
                                                style={{ backgroundColor: 'red', fontSize: '10px' }}
                                            >
                                                Deactivate
                                            </Button>

                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                        ))}
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default AdminPortal;
