import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';  
import Home from './Components/Home';
import ZoneWise from './Components/ZoneWise';
import VehicleWise from './Components/VehicleWise';
import Durationreport from './Components/DurationReport';
import LaborReport from './Components/LaborReport';
import AdminPortal from './Components/AdminPortal';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/zonewise" element={<ZoneWise />} />
          <Route exact path="/vehiclewise" element={<VehicleWise />} />
          <Route exact path="/duration" element={<Durationreport />} />
          <Route exact path="/labor" element={<LaborReport />} />
          <Route exact path="/admin" element={<AdminPortal />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
