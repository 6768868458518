import React, { useState, useEffect } from 'react';
import ExcelJS from 'exceljs';
import instance from '../AxiosInstance';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography, Input } from '@material-ui/core';
import Navbar from './Navbar';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: "55px 0px",
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#ddd',
        padding: '0px 20px',
        marginBottom: '20px',
    },
    inputWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    inputField: {
        margin: '10px',
        padding: '6px',
        backgroundColor: '#f9f9f9',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: '130px',
        fontSize: "1px"
    },
    button: {
        padding: '5px',
        borderRadius: '8px',
        color: '#fff',
        fontSize: '10px',
        margin: '0px 10px'
    },
    input: {
        marginRight: theme.spacing(1),
        height: '35px',
        width: '250px',
        margin: '10px',
        backgroundColor: '#fff',
        borderRadius: '5px'
    },
    dataList: {
        backgroundColor: '#f0f0f0',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: '300px',
        maxHeight: '200px',
        overflowY: 'auto',
    },
}));

const columnsMap = {
    ro_id: `RO ID`,
    vehicle_number: `Vehicle Number`,
    model: 'Model',
    tag_number: 'Tag Number',
    sa_name: 'SA Name',
    technician_name: 'Technician Name',
    site_in_time: 'Site In Time',
    ro_open_date_time: 'RO Open Date Time',
    time_taken_to_open_jc: 'Time Taken to Open JC',
    shopfloor_in_time: 'Shop Floor In Time',
    parking_waiting_time: 'Parking Waiting Time',
    bay_in_time: 'Bay In Time',
    shop_floor_waiting_time: 'Shop Floor Waiting Time',
    bay_out_time: 'Bay Out Time',
    total_bay_time: 'Total Bay Time',
    shopfloor_out_time: 'Shop Floor Out Time',
    total_shopfloor_time: 'Total ShopFloor Time',
    washing_zone_in_time: 'Washing Zone In Time',
    washing_wait_time: 'Washing Wait Time',
    washing_zone_out_time: 'Washing Zone Out Time',
    total_parking_time: 'Total Parking Time',
    total_washing_time: 'Total Washing Time',
    ro_closing_date_time: 'RO Closing Date Time',
    site_out_time: 'Site Out Time',
    delivery_wait_time: 'Delivery Wait Duration',
    total_site_duration: 'Total Site Duration',
    ro_duration: 'RO Duration',
    labor_hours: 'Labor Hours',
    technician_efficiency: 'Technician Efficiency',
    labor_desc: 'Complete Labor Description',
};

const formatValue = (col, value) => {
    if (value === null || value === undefined || value === ' ') {
        return ' ';
    }

    if (typeof value === 'object') {
        let formattedValue = '';

        if (value.hasOwnProperty('days')) {
            formattedValue += `${value.days} days `;
        }
        if (value.hasOwnProperty('hours')) {
            formattedValue += `${value.hours} hours `;
        }
        if (value.hasOwnProperty('minutes')) {
            formattedValue += `${value.minutes} minutes `;
        }
        if (value.hasOwnProperty('seconds')) {
            formattedValue += `${value.seconds} seconds`;
        }
        formattedValue = formattedValue.trim();
        return formattedValue !== '' ? formattedValue : ' ';
    }

    return value.toString();
};


const LaborReport = () => {
    const classes = useStyles();

    const currentDate = new Date().toISOString().split('T')[0];

    const [startDate, setStartDate] = useState('2024-02-10');
    const [endDate, setEndDate] = useState(currentDate);
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [data, setData] = useState([]);
    const [uniqueVehicleNumbers, setUniqueVehicleNumbers] = useState([]);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const filteredData = data.filter(item => item.vehicle_number === selectedVehicle || selectedVehicle === '');
        setTableData(filteredData);
    }, [data, selectedVehicle]);

    const timeColumns = [
        'site_in_time',
        'ro_open_date_time',
        'ro_closing_date_time',
        'site_out_time',
        'washing_zone_out_time',
        'bay_in_time',
        'bay_out_time',
        'shopfloor_out_time',
        'washing_zone_in_time',
        'shopfloor_in_time'
    ];

    const greenColumns = [
        'labor_desc',
        'labor_hours',
        'ro_closing_date_time',
        'ro_open_date_time',
        'technician_name',
        'sa_name',
        'model',
        'vehicle_number',
        'ro_id',
    ];

    const orangeColumns = [
        'site_out_time',
        'washing_zone_out_time',
        'washing_zone_in_time',
        'shopfloor_out_time',
        'bay_out_time',
        'bay_in_time',
        'shopfloor_in_time',
        'site_in_time',
        'tag_number',
    ];

    const fetchData = async () => {
        try {
            const apiUrl = `/api/iwary/GetLaborReportByDate?startDate=${startDate} 00:00:00&endDate=${endDate} 23:59:59`;
            const response = await instance(apiUrl);

            const jsonData = await response.data;
            setData(jsonData);

            const uniqueVehicles = [...new Set(jsonData.map(item => item.vehicle_number))];
            setUniqueVehicleNumbers(uniqueVehicles);
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleFilter = () => {
        fetchData();
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleSelect = (event) => {
        setSelectedVehicle(event.target.value);
    };

    const handlereset = (event) => {
        setSelectedVehicle('');
    };

    const handleDownload = async () => {
        try {
            if (data.length > 0) {
                console.log('data.length: ', data.length);
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Security Data');
                const headers = [
                    'RO ID',
                    'Vehicle Number',
                    'Model',
                    'Tag Number',
                    'SA Name',
                    'Technician Name',
                    'Site In Time',
                    'RO Open Date Time',
                    'Time Taken to Open JC',
                    'Shop Floor In Time',
                    'Parking Waiting Time',
                    'Bay In Time',
                    'Shop Floor Waiting Time',
                    'Bay Out Time',
                    'Total Bay Time',
                    'Shop Floor Out Time',
                    'Total ShopFloor Time',
                    'Washing Zone In Time',
                    'Washing Wait Time',
                    'Washing Zone Out Time',
                    'Total Parking Time',
                    'Total Washing Time',
                    'RO Closing Date Time',
                    'Site Out Time',
                    'Delivery Wait Duration',
                    'Total Site Duration',
                    'RO Duration',
                    'Labor Hours',
                    'Technician Efficiency',
                    'Complete Labor Description',
                ];
    
                worksheet.addRow(headers);
    
                const greenColumns = [
                    'Technician Name',
                    'SA Name',
                    'Model',
                    'Vehicle Number',
                    'RO ID',
                    'RO Open Date Time',
                    'RO Closing Date Time',
                    'Labor Hours',
                    'Complete Labor Description'
                ];
    
                const orangeColumns = [
                    'Site Out Time',
                    'Washing Zone Out Time',
                    'Washing Zone In Time',
                    'Shop Floor Out Time',
                    'Bay Out Time',
                    'Bay In Time',
                    'Shop Floor In Time',
                    'Site In Time',
                    'Tag Number',
                ];
    
                // Set background colors and column widths for header cells
                headers.forEach((header, index) => {
                    const cell = worksheet.getCell(1, index + 1); // ExcelJS cells are 1-based
                    if (greenColumns.includes(header)) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FF00FF00' }, // Green color
                        };
                    } else if (orangeColumns.includes(header)) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFFA500' }, // Orange color
                        };
                    } else {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFFFF00' }, // Yellow color
                        };
                    }
    
                    const columnWidth = Math.max(15, header.length + 2); // Minimum width of 15 characters
                    worksheet.getColumn(index + 1).width = columnWidth;
                });
    
                data.forEach((rowData) => {
                    const values = Object.values(rowData);
                    worksheet.addRow(values);
                });
    
                const buffer = await workbook.xlsx.writeBuffer();
    
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
    
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'Labor_Report.xlsx';
    
                document.body.appendChild(link);
                link.click();
    
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } else {
                alert('No data available to generate Excel.');
            }
        } catch (error) {
            console.error('Error generating Excel:', error);
            alert('Error generating Excel. Please try again.');
        }
    };
    
    

    return (
        <>
            <Navbar />
            <div className={classes.container}>
                <div className={classes.header}>
                    <div className={classes.inputWrapper}>
                        <TextField
                            type="date"
                            value={startDate}
                            onChange={(e) => handleStartDateChange(e.target.value)}
                            className={classes.inputField}
                            InputProps={{
                                style: { fontSize: '12px' },
                            }}
                        />
                        <TextField
                            type="date"
                            value={endDate}
                            onChange={(e) => handleEndDateChange(e.target.value)}
                            className={classes.inputField}
                            InputProps={{
                                style: { fontSize: '12px' },
                            }}
                        />
                        <Button onClick={handleFilter} variant="contained" color="primary" className={classes.button}>
                            Search
                        </Button>
                    </div>
                    <h3 variant="h6">Labor Report</h3>
                    <div className={classes.inputWrapper} style={{ justifyContent: 'space-around' }}>
                        <Input
                            value={selectedVehicle}
                            onChange={handleSelect}
                            placeholder="Select or type Vehicle"
                            className={classes.input}
                            inputProps={{
                                list: 'vehicleList',
                                style: {
                                    padding: '15px',
                                    fontSize: '12px'
                                },
                            }}
                        />
                        <datalist id="vehicleList" className={classes.dataList}>
                            {uniqueVehicleNumbers.map((vehicleNumber) => (
                                <option key={vehicleNumber} value={vehicleNumber} />
                            ))}
                        </datalist>
                        <Button onClick={handlereset} variant="contained" color="secondary" className={classes.button}>
                            Reset
                        </Button>
                        <Button onClick={handleDownload} variant="contained" color="primary" className={classes.button}>
                            Download
                        </Button>
                    </div>
                </div>
                <div id="table-container" style={{ margin: '20px 0', overflow: 'auto', maxHeight: '75vh' }}>
                    {tableData.length > 0 && (
                        <table style={{ borderCollapse: 'collapse', width: '100%', borderRadius: '8px', margin: '10px' }}>
                            <thead style={{ position: 'sticky', top: '0', zIndex: '1', backgroundColor: '#f0f0f0' }}>
                                <tr>
                                    {Object.keys(columnsMap).map(key => (
                                        <th key={key} style={{
                                            fontSize: '12px',
                                            border: '1px solid #aaa',
                                            padding: '10px',
                                            borderRadius: '2px',
                                            minWidth: '120px',
                                            maxWidth: '700px',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            backgroundColor: greenColumns.includes(key) ? 'lightgreen' : orangeColumns.includes(key) ? 'orange' : 'yellow',
                                        }}>
                                            {columnsMap[key]}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {Object.keys(columnsMap).map((col, colIndex) => (
                                            <td key={colIndex} style={{
                                                border: '1px solid #ccc', padding: '5px', borderRadius: '8px', minWidth: '120px',
                                                maxWidth: '700px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '10px', textAlign: 'right'
                                            }}>
                                                {(() => {
                                                    const value = row[col];
                                                    if (timeColumns.some(timeCol => col.includes(timeCol))) {
                                                        if (value !== null && value.trim() !== '') {
                                                            // return moment(value).format('MMMM Do YYYY, h:mm:ss a');
                                                            return value
                                                        } else {
                                                            return " ";
                                                        }
                                                    }

                                                    return formatValue(col, value);
                                                })()}
                                            </td>
                                        ))}
                                    </tr>

                                ))}
                            </tbody>
                        </table>
                    )}
                    {tableData.length === 0 && <p>No data to display.</p>}
                </div>

            </div>
        </>
    );
};

export default LaborReport;
