import { Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import ExcelJS from 'exceljs';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import instance from '../AxiosInstance';
import Navbar from './Navbar';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(2),
        marginTop: theme.spacing(7),
        backgroundColor: '#f0f0f0',
        padding: theme.spacing(1.5),
        justifyContent: 'space-around'
    },
    dateContainer: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    datePicker: {
        width: 150,

    },
    button: {
        marginLeft: theme.spacing(1),
        fontSize: '0.6rem',
    },
    formControl: {
        minWidth: 150,
    },
    heading: {
        margin: theme.spacing(2, 0),
        textAlign: 'center',
    },
    cardsContainer: {
        display: 'flex',
        gap: theme.spacing(2),
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f0f0f0',
    },
    card: {
        width: '250px', height: '100px',
        borderRadius: theme.spacing(1),
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',

    },
    cardHeader: {

        fontSize: '13px',
        backgroundColor: '#555',
        color: '#fff',
        padding: theme.spacing(1),
        borderTopLeftRadius: theme.spacing(1),
        borderTopRightRadius: theme.spacing(1),
        backgroundColor: '#555',
    },
    cardBody: {
        padding: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
        marginTop: '10px',
        fontSize: '15px'
    },

    table: {
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
    },
    tableHeaderRow: {
        backgroundColor: '#f0f0f0',
    },
    tableBodyRow: {
        backgroundColor: '#ffffff',
        border: '1px solid #e0e0e0',
        '& > td': {
            padding: '12px',
            margin: '4px',
        },
    },
    tableContainer: {
        marginTop: theme.spacing(2),
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        fontSize: '10px'
    },
    activePage: {
        fontWeight: 'bold',
    },
}));

const Home = () => {
    const classes = useStyles();
    const startDateForApi = new Date('2024-02-10');
    const endDateForApi = new Date()
    const svgRef = useRef(null);
    const svgRef2 = useRef(null);
    const itemsPerPage = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [firstViolation, setfirstViolation] = useState("All");
    const [SecondViolation, setSecondViolation] = useState("All");
    const [unfliter, setFliter] = useState([]);
    const [unfilterReport, setUnfilterReport] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [status, setStatus] = useState("All");
    const [cardData, setCardData] = useState([])


    useEffect(() => {
        const fetchData = async () => {
            try {
                const token =  process.env.REACT_APP_TOKEN
                const response = await instance.get('/api/iwary/getAllSecurityData', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setFliter(response.data)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const fetchData2 = async () => {
            try {
                const token =  process.env.REACT_APP_TOKEN
                 const result = await instance.get('/api/iwary/getAllstationstatus', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                renderTable(result.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const fetchData3 = async () => {
            try {
                const token =  process.env.REACT_APP_TOKEN
                const apiUrl = `/api/iwary/reportPerDate?startDate=${startDateForApi.toISOString()}&endDate=${endDateForApi.toISOString()}`;
                const response = await instance.get(apiUrl, {

                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: 'application/json',
                    },
                });

                let filteredData = response.data;

                setUnfilterReport(response.data)

            } catch (error) {
                console.error('Error fetching data:', error);

            }
        };

        fetchData3();
        fetchData2();
        fetchData();
    }, []);


    let reportData;
    let uniqueVehicleNumbers

    if (SecondViolation == "All" && firstViolation == "All") {
        reportData = unfilterReport;
        uniqueVehicleNumbers = [...new Set(reportData.map(item => item.vehiclenumber))]

    }
    if (firstViolation == "Violated" && SecondViolation == "All") {
        reportData = unfilterReport.filter((item) => item.firstescalation === firstViolation);
        uniqueVehicleNumbers = [...new Set(reportData.map(item => item.vehiclenumber))]

    }
    if (firstViolation == "Not Violated" && SecondViolation == "All") {
        reportData = unfilterReport.filter((item) => item.firstescalation === firstViolation);
        uniqueVehicleNumbers = [...new Set(reportData.map(item => item.vehiclenumber))]

    }
    if (SecondViolation == "Violated" && firstViolation == "All") {
        reportData = unfilterReport.filter((item) => item.secondescalation === SecondViolation);
        uniqueVehicleNumbers = [...new Set(reportData.map(item => item.vehiclenumber))]
    }
    if (SecondViolation == "Not Violated" && firstViolation == "All") {

        reportData = unfilterReport.filter((item) => item.secondescalation === SecondViolation);
        uniqueVehicleNumbers = [...new Set(reportData.map(item => item.vehiclenumber))]

    }
    if (SecondViolation == "Not Violated" && firstViolation == "Violated") {
        reportData = unfilterReport.filter((item) => item.secondescalation === SecondViolation);
        uniqueVehicleNumbers = [...new Set(reportData.map(item => item.vehiclenumber))]

    }
    if (SecondViolation == "Violated" && firstViolation == "Not Violated") {
        reportData = unfilterReport.filter((item) => item.secondescalation === SecondViolation);
        uniqueVehicleNumbers = [...new Set(reportData.map(item => item.vehiclenumber))]

    }
    if (SecondViolation == "Violated" && firstViolation == "Violated") {
        reportData = unfilterReport.filter((item) => item.secondescalation === SecondViolation);
        uniqueVehicleNumbers = [...new Set(reportData.map(item => item.vehiclenumber))]

    }
    if (SecondViolation == "Not Violated" && firstViolation == "Not Violated") {
        reportData = unfilterReport.filter((item) => item.secondescalation == SecondViolation);
        uniqueVehicleNumbers = [...new Set(reportData.map(item => item.vehiclenumber))]

    }



    let vehicleData;

    if (status !== "All" && (firstViolation !== "All" || SecondViolation != "All")) {

        vehicleData = unfliter.filter((item) => {
            const statusMatch = !status || item.status === status;
            const violationMatch = uniqueVehicleNumbers.includes(item.vehiclenumber);
            return statusMatch && violationMatch;
        });
    }
    else if (status !== "All" && (firstViolation == "All" && SecondViolation == "All")) {
        vehicleData = unfliter.filter((item) => {

            const statusMatch = !status || item.status === status;
            return statusMatch

        });

    }
    else if (status == "All" && SecondViolation == "All" && firstViolation == "All") {
        vehicleData = unfliter;
    }
    else if (status == "All" && (firstViolation !== "All" || SecondViolation != "All")) {
        vehicleData = unfliter.filter((item) => {
            const violationMatch = uniqueVehicleNumbers.includes(item.vehiclenumber);

            return violationMatch;
        });
    }


    const FetchDateData = async () => {
        try {
            if (!startDate || !endDate) {
                throw new Error('Please select both start date and end date.');
            }

            const formattedStartDate = new Date(startDate);
            formattedStartDate.setHours(0, 0, 0);
            const formattedEndDate = new Date(endDate);
            formattedEndDate.setHours(23, 59, 59);

            const securityResponse = await instance.get(`/api/iwary/getSecurityDataPerDate?startDate=${encodeURIComponent(formattedStartDate.toISOString())}&endDate=${encodeURIComponent(formattedEndDate.toISOString())}`);
            setFliter(securityResponse.data);

            const reportResponse = await instance.get(`/api/iwary/reportPerDate?startDate=${encodeURIComponent(formattedStartDate.toISOString())}&endDate=${encodeURIComponent(formattedEndDate.toISOString())}`);
            setUnfilterReport(reportResponse.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            window.alert(error.message);
        }
    };

    useEffect(() => {

        if (unfliter.length > 0) {

            const totalCount = reportData.length;
            const totalJobs = vehicleData.length
            const entryCount = vehicleData.filter((item) => item.status === 'Entry').length;

            const exitCount = vehicleData.filter((item) => item.status === 'Exit').length;
            const FisrtViolationsCount = reportData.filter((item) => item.firstescalation === 'Violated').length;

            const SecondViolationsCount = reportData.filter((item) => item.secondescalation === 'Violated').length;


            const cardsData1 = [
                { label: 'Total vs First Level', value: `${((FisrtViolationsCount / totalCount) * 100).toFixed(2)}%`, color: '#CCC', textColor: '#000' },
                { label: 'Total vs Second Level', value: `${((SecondViolationsCount / totalCount) * 100).toFixed(2)}%`, color: '#CCC', textColor: '#000' },
                { label: 'Total Jobs', value: totalJobs, color: '#CCC', textColor: '#000' },
                { label: 'Active Jobs', value: entryCount, color: '#CCC', textColor: '#000' },
                { label: 'Completed Jobs', value: exitCount, color: '#CCC', textColor: '#000' },
                { label: 'First Level Violations', value: FisrtViolationsCount, color: '#CCC', textColor: '#000' },
                { label: 'Second Level Violations', value: SecondViolationsCount, color: '#CCC', textColor: '#000' },
            ];
            setCardData(cardsData1)
        }
        if (unfliter.length > 0) {

            d3.select(svgRef2.current).selectAll("*").remove();
            const entryData = vehicleData.filter((item) => item.status === 'Entry');
            const exitData = vehicleData.filter((item) => item.status === 'Exit');

            const entryCount = entryData.length;
            const exitCount = exitData.length;

            const margin = { top: 20, right: 30, bottom: 40, left: 60 };
            const width = 600 - margin.left - margin.right;
            const height = 200 - margin.top - margin.bottom;

            const svg = d3.select(svgRef2.current)
                .attr('width', width + margin.left + margin.right)
                .attr('height', height + margin.top + margin.bottom)
                .append('g')
                .attr('transform', `translate(${margin.left},${margin.top})`);

            const xScale = d3.scaleLinear()
                .domain([0, Math.max(entryCount, exitCount)])
                .range([0, width]);

            const yScale = d3.scaleBand()
                .domain(['Entry', 'Exit'])
                .range([0, height])
                .padding(0.1);

            svg.append('g')
                .attr('class', 'x-axis')
                .attr('transform', `translate(0, ${height})`)
                .call(d3.axisBottom(xScale));

            svg.append('g')
                .attr('class', 'y-axis')
                .call(d3.axisLeft(yScale));

            svg.selectAll('.bar')
                .data([{ label: 'Entry', value: entryCount }, { label: 'Exit', value: exitCount }])
                .enter()
                .append('rect')
                .attr('class', 'bar')
                .attr('x', 0)
                .attr('y', (d) => yScale(d.label))
                .attr('width', (d) => xScale(d.value))
                .attr('height', yScale.bandwidth())
                .attr('fill', (d, i) => i === 0 ? 'rgba(255, 99, 132, 0.5)' : 'rgba(54, 162, 235, 0.5)');

            svg.selectAll('.bar-label')
                .data([{ label: 'Entry', value: entryCount }, { label: 'Exit', value: exitCount }])
                .enter()
                .append('text')
                .attr('class', 'bar-label')
                .text((d) => d.value)
                .attr('x', (d) => xScale(d.value) + 10)
                .attr('y', (d) => yScale(d.label) + yScale.bandwidth() / 2)
                .attr('dy', '0.35em')
                .attr('font-family', 'sans-serif')
                .attr('font-size', '12px')
                .attr('fill', 'black')
                .attr('text-anchor', 'start');
        }

    }, [vehicleData, reportData, unfliter, unfilterReport]);

    //for jobs 
    const tableRef = useRef(null);
    useEffect(() => {
        if (vehicleData.length >= 0) {
            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            const paginatedData = vehicleData.slice(startIndex, endIndex);

            const table = d3.select(tableRef.current);
            table.selectAll('*').remove();

            const headers = ['id', 'tagnumber', 'vehiclenumber', 'status', 'first_escalation', 'second_escalation', 'intime'];

            const renamedHeaders = {
                id: 'ID',
                tagnumber: 'Tag Number',
                vehiclenumber: 'Vehicle Number',
                status: 'Status',
                first_escalation: '1st Escalation ',
                second_escalation: '2nd Escalation  ',
                intime: 'Date',
            };

            const tableHeader = table.append('thead').append('tr');
            const tableBody = table.append('tbody');

            paginatedData.sort((a, b) => a.id - b.id);

            tableHeader
                .selectAll('th')
                .data(headers)
                .enter()
                .append('th')
                .text((d) => renamedHeaders[d])
                .style('border', '1px solid #e0e0e0')
                .style('background-color', '#f0f0f0')
                .style('padding', '8px')
                .style('margin', '0px')
                .style('border-collapse', 'collapse')
                .style('font-size', '12px');

            const rows = tableBody.selectAll('tr').data(paginatedData).enter().append('tr');
            rows
                .selectAll('td')
                .data((row) =>
                    headers.map((header) => {
                        if (header === 'intime') {
                            const dateObj = new Date(row[header]);
                            return { header: renamedHeaders[header], value: dateObj.toLocaleDateString() };
                        } else {
                            return { header: renamedHeaders[header], value: row[header] };
                        }
                    })
                )
                .enter()
                .append('td')
                .text((d) => d.value)
                .style('text-align', 'center')
                .style('border', '1px solid #e0e0e0')
                .style('padding', '7px')
                .style('margin', '0px')
                .style('border-collapse', 'collapse')
                .style('font-size', '12px');
        }
    }, [vehicleData, currentPage]);

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <Button
                    key={i}
                    onClick={() => handlePageClick(i)}
                    className={i === currentPage ? 'active' : ''}
                >
                    {i}
                </Button>
            );
        }
        return pages;
    };
    const totalPages = Math.ceil(vehicleData.length / itemsPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    const tableref2 = useRef(null);

    const renderTable = (data) => {
        const table = d3.select(tableref2.current);

        const tableBody = table.select('tbody');

        const columns = ['ID', 'Status', 'Status At', 'Name'];

        const rows = tableBody.selectAll('tr').data(data, (d) => d.id);
        rows.exit().remove();
        const newRows = rows.enter().append('tr');

        const cells = newRows
            .selectAll('td')
            .data((row) => {
                return columns.map((column) => {
                    if (column === 'ID') {
                        return row.id;
                    } else if (column === 'Status') {
                        return row.status;
                    } else if (column === 'Status At') {
                        return row.status_at
                    } else if (column === 'Name') {
                        return row.name;
                    }
                });
            })
            .enter()
            .append('td')
            .text((d) => d)
            .style('font-size', '13px')
            .style('padding', '8px')
            .style('margin', '4px')
            .style('border', '1px solid #e0e0e0');

        cells.style('background-color', (d, i) => {

            if (columns[i] === 'Status' && d === 'Active') {
                return '#9aff91';
            } else if (columns[i] === 'Status') {
                return '#fa6161';
            } else {
                return null;
            }
        });


        rows.merge(newRows);
    };



    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const handleFirstChange = (e) => {
        setfirstViolation(e.target.value);
    };

    const handleSeconfchange = (e) => {
        setSecondViolation(e.target.value);
    };

    const handleDownload = async () => {
        try {
            if (vehicleData.length > 0) {
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Security Data');


                const headers = Object.keys(vehicleData[0]);
                worksheet.addRow(headers);


                vehicleData.forEach((rowData) => {
                    const values = Object.values(rowData);
                    worksheet.addRow(values);
                });


                const buffer = await workbook.xlsx.writeBuffer();


                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });


                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'JOBs_Report.xlsx';
                document.body.appendChild(link);


                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } else {
                alert('No data available to generate Excel.');
            }
        } catch (error) {
            console.error('Error generating Excel:', error);
            alert('Error generating Excel. Please try again.');
        }
    };


    return (
        <div>
            <Navbar />
            <div className={classes.container}>
                <div className={classes.dateContainer} >
                    <TextField
                        id="startDate"
                        label="Start Date"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className={classes.datePicker}
                        InputProps={{
                            style: { fontSize: '12px' },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: { fontSize: '13px' }
                        }}
                    />
                    <TextField
                        id="endDate"
                        label="End Date"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className={classes.datePicker}
                        InputProps={{
                            style: { fontSize: '12px' },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: { fontSize: '13px' }

                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={FetchDateData}
                        className={classes.button}
                    >
                        Search
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDownload}
                        className={classes.button}

                    >
                        Download
                    </Button>
                </div>
                <div className={classes.dateContainer} >
                    <FormControl className={classes.formControl}>
                        <InputLabel id="status-label" style={{ fontSize: '12px' }}>Select Status</InputLabel>
                        <Select
                            labelId="status-label"
                            id="status"
                            value={status}
                            onChange={handleStatusChange}
                            style={{ fontSize: '12px' }}
                        >
                            <MenuItem value="All">All Status</MenuItem>
                            <MenuItem value="Entry">Entry</MenuItem>
                            <MenuItem value="Exit">Exit</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="first-violation-label" style={{ fontSize: '12px' }}>First Violation</InputLabel>
                        <Select
                            labelId="first-violation-label"
                            id="firstViolation"
                            value={firstViolation}
                            onChange={handleFirstChange}
                            style={{ fontSize: '12px' }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="Violated">Violated</MenuItem>
                            <MenuItem value="Not Violated">Not Violated</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="second-violation-label" style={{ fontSize: '12px' }}>Second Violation</InputLabel>
                        <Select
                            labelId="second-violation-label"
                            id="secondViolation"
                            value={SecondViolation}
                            onChange={handleSeconfchange}
                            style={{ fontSize: '12px' }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="Violated">Violated</MenuItem>
                            <MenuItem value="Not Violated">Not Violated</MenuItem>
                        </Select>
                    </FormControl>

                </div>
            </div>
            <div>
                <h3 className={classes.heading}>Details of Jobs</h3>
                <div className={classes.cardsContainer}>
                    {cardData.map((card, index) => (
                        <Card className={classes.card} key={index} >
                            <Typography variant="h6" className={classes.cardHeader}  >
                                {card.label}
                            </Typography>
                            <CardContent className={classes.cardBody}>
                                <Typography variant="body3" style={{ fontSize: '18px', fontweight: 'bold' }} color="textSecondary">
                                    {card.value}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            </div>
            <div style={{ margin: '20px' }}>
                <Grid container  >
                    <Grid item xs={6} >
                        <div>
                            <h3 style={{ textAlign: 'center' }}  >Jobs Table</h3>
                            <div className={classes.tableContainer}>
                                <Table className="data-table" ref={tableRef}>

                                </Table>
                            </div>
                            <div className={classes.pagination}>
                                <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                                    Prev
                                </Button>
                                {renderPageNumbers()}
                                <Button onClick={handleNextPage} disabled={currentPage >= totalPages}>
                                    Next
                                </Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6} >
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                            <div style={{ margin: '0px 30px 30px 50px' }}>
                                <h3 style={{ textAlign: 'center' }}>Vehicle Entry Exit Graph</h3>
                                <svg ref={svgRef2}></svg>
                            </div>
                            <div style={{ margin: '10px 20px 10px 20px' }}>
                                <div style={{ width: '100%', margin: '-40px 20px 10px 20px' }}>
                                    <div className="data-table-container">
                                        <h3 style={{ marginBottom: 20, textAlign: 'center' }}>Station Status Details</h3>
                                        <Table className={classes.table} ref={tableref2}>
                                            <TableHead>
                                                <TableRow className={classes.tableHeaderRow}>
                                                    <TableCell>ID</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Status At</TableCell>
                                                    <TableCell>Name</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className={classes.tableBodyRow}>

                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>

        </div>
    );
};

export default Home;
