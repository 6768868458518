import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Logo from '../Components/Images/LOGO.png';
import Map from '../Components/Images/map.png';
import Mahindra from '../Components/Images/Mahindra-SUV.png' 

const navStyle = {
  backgroundColor: 'rgb(51, 51, 51)',
  padding: '5px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 1000,
};

const navLeftStyle = {
  display: 'flex',
  alignItems: 'center',
};

const navLinkStyle = {
  textDecoration: 'none',
  color: 'white',
  padding: '7px 12px',
  backgroundColor: '#555',
  borderRadius: '3px',
  margin: '0 5px',
  fontSize: '13px',
  textTransform: 'none',
};

const importBtnStyle = {
  backgroundColor: 'rgb(51, 51, 51)',
  color: 'white',
  padding: '5px 10px',
  cursor: 'pointer',
  marginRight: '20px',
  margin: '10px 20px',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
};

const Navbar = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div style={navStyle}>
      <div style={navLeftStyle}>
      <img style={{ width: '50px', margin: '0px 0px 0px 10px' }} src={Mahindra} alt="Powered By Image" />

        <Typography variant="h6" style={{ color: 'white', marginLeft: '2px' }}>
          Vihaan Dashboard
        </Typography>
      </div>
      <div>
        <ul style={{ listStyleType: 'none', margin: 0, padding: 0, display: 'flex' }}>
          <li>
            <Button style={navLinkStyle} onClick={() => handleNavigation('/')}>
              Home
            </Button>
          </li>
          <li>
            <Button style={navLinkStyle} onClick={() => handleNavigation('/zonewise')}>
              Zonewise Report
            </Button>
          </li>
          <li>
            <Button style={navLinkStyle} onClick={() => handleNavigation('/vehiclewise')}>
              Vehiclewise Report
            </Button>
          </li>
          <li>
            <Button style={navLinkStyle} onClick={() => handleNavigation('/duration')}>
              Duration Report
            </Button>
          </li>
          <li>
            <Button style={navLinkStyle} onClick={() => handleNavigation('/labor')}>
              Labor Report
            </Button>
          </li>
          <li>
            <Button style={{ ...navLinkStyle, backgroundColor: '#487043' }}>
              <img src={Map} alt="GPS Icon" style={{ marginRight: '5px',width:'17px'  }} />
              Map
            </Button>
          </li>
          <li>
            <Button style={{ ...navLinkStyle, backgroundColor: '#9e3e46' }} onClick={() => handleNavigation('/admin')}>
              Admin
            </Button>
          </li>
        </ul>
      </div>
      <div style={importBtnStyle}>
        <img style={{ width: '100px', margin: '0px 10px' }} src={Logo} alt="Powered By Image" />
      </div>
    </div>
  );
};

export default Navbar;
