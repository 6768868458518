import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as d3 from 'd3';
import ExcelJS from 'exceljs';
import { Grid, InputLabel, Select, MenuItem, TextField, Typography, Box, makeStyles } from '@material-ui/core';

import instance from '../AxiosInstance';
import Navbar from './Navbar';
const zones = {
    ALL: 'ALL',
    SHOPFLOOR: 'SHOPFLOOR',
    Qwik1: 'QWIK1',
    Qwik2: 'QWIK2',
    SER1: 'SER1',
    SER2: 'SER2',
    SER3: 'SER3',
    EV1: 'EV1',
    WashingZone: 'WASHING_ZONE',
    Site: 'Site'
};
const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginTop: '55px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    header: {
        backgroundColor: '#f0f0f0',
        padding: '2px',
        borderRadius: '5px',
        width: '100%',
    },
    searchForm: {
        display: 'flex',
        flexDirection: 'row',
        width: '75%',
        justifyContent: 'space-around'
    },
    formRow: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 0px',
        margin: '0px 5px'
    },
    formInput: {
        marginRight: '10px',
    },
    searchButton: {
        backgroundColor: '#f0f0f0',
        margin: '10px',
        padding: '8px 16px',
        borderRadius: '5px',
        cursor: 'pointer',
        marginBottom: '10px',
        alignItems: 'center'
    },
    barButton: {
        backgroundColor: '#f0f0f0',
        margin: '10px',
        padding: '8px 16px',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '50px'
    },
}));



const ZoneWise = () => {
    const classes = useStyles();

    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [selectedZone, setSelectedZone] = useState('ALL');
    const [vehicleNumber, setVehicleNumber] = useState('ALL');
    const [vehicleNumbers, setVehicleNumbers] = useState([]);
    const [yes, setYes] = useState(false);



    const handleSearch = () => {

        const formattedStartDate = new Date(selectedStartDate);
        formattedStartDate.setHours(0, 0, 0);

        const formattedEndDate = new Date(selectedEndDate);
        formattedEndDate.setHours(23, 59, 59);
        setIsLoading(true);
        const apiUrl = `/api/iwary/reportPerDate?startDate=${encodeURIComponent(formattedStartDate.toISOString())}&endDate=${encodeURIComponent(formattedEndDate.toISOString())}`;
        instance(apiUrl, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        })
            .then((response) => response.data)
            .then((data) => {
                let filteredData = data;
                if (selectedZone == 'ALL') {
                    filteredData = filteredData
                }
                if (selectedZone !== 'ALL') {
                    filteredData = filteredData.filter((item) => {
                        return item.zoneid === selectedZone;
                    });
                }
                if (vehicleNumber !== 'ALL') {
                    filteredData = filteredData.filter((item) => item.vehiclenumber === vehicleNumber);
                }

                const uniqueVehicleNumbers = data.reduce((acc, curr) => {
                    if (!acc.includes(curr.vehiclenumber)) {
                        acc.push(curr.vehiclenumber);
                    }
                    return acc;
                }, []);

                setVehicleNumbers(uniqueVehicleNumbers);
                setTableData(filteredData);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (tableData.length > 0) {
            tableData.sort((a, b) => new Date(a.intime) - new Date(b.intime));
            d3.select('.table-container').selectAll('*').remove();

            const table = d3.select('.table-container').append('table').attr('class', 'custom-table');
            const thead = table.append('thead');
            const tbody = table.append('tbody');

            const columnNames = {
                id: 'ID',
                vehiclenumber: 'Vehicle Number',
                zoneid: 'Zone',
                status: 'Status',
                firstescalation: 'First Escalation',
                secondescalation: 'Second Escalation',
                intime: 'In Time',
                outime: 'Out Time',
                duration: 'Duration',
            };

            thead
                .append('tr')
                .selectAll('th')
                .data(Object.keys(columnNames).filter((key) => key !== 'job_id'))
                .enter()
                .append('th')
                .text((key) => columnNames[key]);

            const rows = tbody.selectAll('tr').data(tableData).enter().append('tr');
            const cells = rows
                .selectAll('td')
                .data((row) => {
                    const formattedData = { ...row };
                    if (formattedData.duration) {
                        const { days, hours, minutes, seconds } = formattedData.duration;
                        let durationString = '';

                        if (days !== undefined) {
                            durationString += `${days} days, `;
                        }
                        if (hours !== undefined) {
                            durationString += `${hours} hours, `;
                        }
                        if (minutes !== undefined) {
                            durationString += `${minutes} minutes, `;
                        }
                        if (seconds !== undefined) {
                            durationString += `${seconds} seconds`;
                        }

                        formattedData.duration = durationString.trim();
                    }

                    return Object.keys(columnNames)
                        .filter((key) => key !== 'job_id')
                        .map((key) => formattedData[key]);
                })
                .enter()
                .append('td')
                .text((value, index, nodes) => {
                    if (Object.keys(columnNames)[index] === 'intime' || Object.keys(columnNames)[index] === 'outtime') {
                        return value ? value : '';
                    }
                    return value;
                });
        }

        const styles = `
.custom-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.custom-table th,
.custom-table td {
    padding: 8px;
    border: 1px solid #ddd;
    white-space: nowrap;  
    font-size: 10px;
}

.custom-table th {
    background-color: #ccc;
    font-weight: bold;
    text-align: left;
    position: sticky;
  top: 0;
  z-index: 1;
}

.custom-table tbody { 
    max-height: 600px;
    overflow-y: auto;
}

.custom-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.custom-table tr:hover {
    background-color: #f2f2f2;
}
`

        d3.select('head').append('style').text(styles);
    }, [tableData]);


    useEffect(() => {
        setTableData([])
        d3.select('.table-container').selectAll('*').remove();
        setSelectedZone('ALL')
        setVehicleNumber('ALL')
    }, [yes])


    const handleyes = () => {
        setYes(!yes)
    }

    const handleDownload = async () => {
        try {
            if (tableData.length > 0) {
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Security Data');

                const headers = Object.keys(tableData[0]);
                worksheet.addRow(headers);

                tableData.forEach((rowData) => {
                    const values = Object.values(rowData);
                    worksheet.addRow(values);
                });

                const buffer = await workbook.xlsx.writeBuffer();

                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });

                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'Zonewise_Report.xlsx';
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } else {
                alert('No data available to generate Excel.');
            }
        } catch (error) {
            console.error('Error generating Excel:', error);
            alert('Error generating Excel. Please try again.');
        }
    };


    return (
        <>
            <Navbar />
            <Grid style={{ width: "100vw" }} container xs={12} sm={10} md={12}>
                <Box className={classes.container}>
                    <Box className={classes.header}>
                        <h4 align="center" style={{ margin: '10px' }}>Zone Wise Report</h4>
                    </Box>
                    <Box className={classes.searchForm}>
                        <Box className={classes.formRow}>
                            <InputLabel style={{ fontSize: '12px' }} className={classes.formInput}>Start Date:</InputLabel>
                            <TextField
                                type="date"
                                value={moment(selectedStartDate).format('YYYY-MM-DD')}
                                onChange={(e) => setSelectedStartDate(new Date(e.target.value))}
                                inputProps={{
                                    style: {
                                        fontSize: '12px'
                                    },
                                }}
                            />
                        </Box>
                        <Box className={classes.formRow}>
                            <InputLabel style={{ fontSize: '12px' }} className={classes.formInput}>End Date:</InputLabel>
                            <TextField
                                type="date"
                                value={moment(selectedEndDate).format('YYYY-MM-DD')}
                                onChange={(e) => setSelectedEndDate(new Date(e.target.value))}
                                inputProps={{
                                    style: {
                                        fontSize: '12px'
                                    },
                                }}
                            />
                        </Box>
                        <Box className={classes.formRow}>
                            <InputLabel style={{ fontSize: '12px' }} className={classes.formInput}>Zone:</InputLabel>
                            <Select
                                style={{ fontSize: '12px' }}
                                value={selectedZone}
                                onChange={(e) => setSelectedZone(e.target.value)}
                            >
                                {Object.keys(zones).map((key) => (
                                    <MenuItem value={zones[key]} key={key}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box className={classes.formRow}>
                            <InputLabel style={{ fontSize: '12px' }} className={classes.formInput}>Vehicle:</InputLabel>
                            <Select
                                style={{ fontSize: '12px' }}
                                value={vehicleNumber}
                                onChange={(e) => setVehicleNumber(e.target.value)}
                            >
                                <MenuItem value="ALL">ALL</MenuItem>
                                {vehicleNumbers.map((vehicle) => (
                                    <MenuItem value={vehicle} key={vehicle}>
                                        {vehicle}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <button onClick={handleyes} className={classes.barButton} style={{ width: '80px', backgroundColor: '#f73942', color: 'white', border: '1px solid #ccc' }}>Reset</button>
                        <button onClick={handleDownload} className={classes.barButton} style={{ width: '100px', backgroundColor: '#1fa31f', color: 'white', border: '1px solid #ccc' }}>Download</button>
                    </Box>
                    <button onClick={handleSearch} className={classes.searchButton} disabled={isLoading}
                        style={{ width: '100px', backgroundColor: '#4287f5', color: 'white', border: '1px solid #ccc' }}>
                        {isLoading ? 'Searching...' : 'Search'}
                    </button>
                    <div style={{ maxHeight: '400px', overflowY: 'auto' }} className="table-container"></div>

                </Box>
            </Grid>

        </>
    );

};

export default ZoneWise;
