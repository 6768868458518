import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import ExcelJS from 'exceljs'; // Import exceljs
import instance from '../AxiosInstance';
import {
    Box,
    Button,
    Input,
    Paper,
    makeStyles,
} from '@material-ui/core';
import Navbar from './Navbar';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '55px 0px 20px 0px',
    },
    header: {
        textAlign: 'center',
        width: '750px'
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 30px',
        backgroundColor: '#eee'
    },
    input: {
        marginRight: theme.spacing(1),
        // height: '48px',
        width: '280px'
    },
    dataList: {
        backgroundColor: '#f0f0f0',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: '300px',
        maxHeight: '200px',
        overflowY: 'auto',
    },
    tableContainer: {
        maxHeight: '600px',
        overflowY: 'auto',
        margin: '10px 30px',
        borderRadius: '5px',
        backgroundColor: '#f0f0f0',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    button: {
        marginLeft: '10px',
        backgroundColor: '#ccc',
        fontSize: '12px'
    },
}));

const VehicleWise = () => {
    const classes = useStyles();
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await instance('/api/iwary/getAllFinalReportData');
            const jsonData = await response.data;
            setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSelect = (e) => {
        setSelectedVehicle(e.target.value);
    };

    const handleReset = () => {
        setSelectedVehicle('');
    };

    const uniqueVehicleNumbers = [...new Set(data.map((item) => item.vehiclenumber))];

    const filteredData = selectedVehicle
        ? data.filter((item) => item.vehiclenumber === selectedVehicle)
        : data;

    const tableData = [];
    filteredData.forEach((item, index) => {
        tableData.push({
            index: index + 1,
            vehicleNumber: item.vehiclenumber,
            finalStatusEnter: `Entered ${item.zoneid}`,
            zone: item.zoneid,
            timeEnter: item.intime,
        });
        tableData.push({
            index: index + 2,
            vehicleNumber: item.vehiclenumber,
            finalStatusExit: `Exited ${item.zoneid}`,
            zone: item.zoneid,
            timeExit: item.outime,
        });
    });

    tableData.sort((a, b) => new Date(a.timeEnter) - new Date(b.timeEnter));

    useEffect(() => {
        const table = d3.select('#vehicleDataTable');
        table.selectAll('*').remove();

        const thead = table.append('thead');
        const tbody = table.append('tbody');

        const columns = ['Index', 'Vehicle number', 'Final status', 'Zone', 'Time'];
        thead.append('tr')
            .selectAll('th')
            .data(columns)
            .enter()
            .append('th')
            .text(column => column);

        const rows = tbody.selectAll('tr')
            .data(tableData)
            .enter()
            .append('tr');

        rows.selectAll('td')
            .data(row => Object.values(row))
            .enter()
            .append('td')
            .text((value, index, nodes) => {
                if (columns[index] === 'Time') {
                    return value
                }
                return value;
            });

        table.style('border-collapse', 'collapse');
        table.style('width', '100%');
        table.selectAll('th, td')
            .style('border', '1px solid #ddd')
            .style('padding', '6px')
            .style('font-size', '12px')
            .style('text-align', 'left');
    }, [tableData]);


    const handleDownload = async () => {
        try {
            if (tableData.length > 0) {
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Security Data');

                const headers = Object.keys(tableData[0]);
                worksheet.addRow(headers);

                tableData.forEach((rowData) => {
                    const values = Object.values(rowData);
                    worksheet.addRow(values);
                });

                const buffer = await workbook.xlsx.writeBuffer();

                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });

                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'Vehiclewise_Report.xlsx';
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } else {
                alert('No data available to generate Excel.');
            }
        } catch (error) {
            console.error('Error generating Excel:', error);
            alert('Error generating Excel. Please try again.');
        }
    };


    return (
        <>
            <Navbar />
            <div className={classes.root}>

                <Box className={classes.inputContainer} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Input
                        value={selectedVehicle}
                        onChange={handleSelect}
                        placeholder="Select or type Vehicle"
                        className={classes.input}
                        inputProps={{
                            list: 'vehicleList',
                            style: {
                                padding: '13px',
                                fontSize: '13px'
                            },

                        }}
                    />
                    <datalist id="vehicleList" className={classes.dataList}>
                        {uniqueVehicleNumbers.map((vehicleNumber) => (
                            <option key={vehicleNumber} value={vehicleNumber} />
                        ))}
                    </datalist>
                    <Button onClick={handleReset} className={classes.button} style={{ backgroundColor: '#f73942', color: 'white' }}>
                        Reset
                    </Button>
                    <h4 variant="h2" className={classes.header}>
                        Vehicle Wise Report
                    </h4>
                    <Button onClick={handleDownload} className={classes.button} style={{ justifyContent: 'flex-end', color: 'white', backgroundColor: '#1fa31f' }}>
                        Download
                    </Button>


                </Box>

                <Paper className={classes.tableContainer}>
                    <table id="vehicleDataTable" className={classes.table}>

                    </table>
                </Paper>
            </div>
        </>
    );
};

export default VehicleWise;
